import {gql} from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  ISO8601DateTime: any
}

export type Article = {
  __typename?: 'Article'
  body: Scalars['String']
  id: Scalars['ID']
}

export type Comment = {
  __typename?: 'Comment'
  body: Scalars['String']
  createdAt: Scalars['ISO8601DateTime']
  id: Scalars['ID']
  student: Student
}

/** The connection type for Comment. */
export type CommentConnection = {
  __typename?: 'CommentConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CommentEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Comment>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

/** An edge in a connection. */
export type CommentEdge = {
  __typename?: 'CommentEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node?: Maybe<Comment>
}

/** Filter operations */
export type Filter = {
  /** Contains value */
  cont?: InputMaybe<Scalars['String']>
  /** Equals value */
  eq?: InputMaybe<Scalars['String']>
  /** Starts with value */
  start?: InputMaybe<Scalars['String']>
}

export type Group = {
  __typename?: 'Group'
  children: Array<GroupChild>
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  name: Scalars['String']
}

/** Descendents of a journey group. */
export type GroupChild = JourneyTile | Milestone

export type Job = {
  __typename?: 'Job'
  city: Scalars['String']
  company: Scalars['String']
  country: Scalars['String']
  date: Scalars['String']
  expired: Scalars['Boolean']
  formattedLocation: Scalars['String']
  formattedLocationFull: Scalars['String']
  id: Scalars['String']
  indeedApply: Scalars['Boolean']
  jobKey: Scalars['String']
  jobTitle: Scalars['String']
  language: Scalars['String']
  onmousedown: Scalars['String']
  snippet: Scalars['String']
  source: Scalars['String']
  sponsored: Scalars['Boolean']
  state: State
  stations: Scalars['String']
  url: Scalars['String']
}

export enum JobEnum {
  /** Contract Jobs */
  Contract = 'CONTRACT',
  /** Full Time jobs */
  Fulltime = 'FULLTIME',
  /** Internships Jobs */
  Internship = 'INTERNSHIP',
  /** Part Time jobs */
  Parttime = 'PARTTIME',
  /** Temporary jobs */
  Temporary = 'TEMPORARY',
}

/** Search for jobs */
export type JobSearch = {
  jobType?: InputMaybe<JobEnum>
  location?: InputMaybe<Scalars['String']>
  query?: InputMaybe<Scalars['String']>
  radius?: InputMaybe<Scalars['Int']>
  sinceDaysAgo?: InputMaybe<Scalars['Int']>
  sort?: InputMaybe<SortEnum>
}

export type JobSearchPageInfo = {
  __typename?: 'JobSearchPageInfo'
  end: Scalars['Int']
  hasMore: Scalars['Boolean']
  pageNumber: Scalars['Int']
  start: Scalars['Int']
  totalCount: Scalars['Int']
}

export type JobSearchResult = {
  __typename?: 'JobSearchResult'
  dupefilter: Scalars['Boolean']
  highlight: Scalars['Boolean']
  location: Scalars['String']
  pageInfo: JobSearchPageInfo
  query: Scalars['String']
  radius?: Maybe<Scalars['Int']>
  results: Array<Job>
}

export type Journey = {
  __typename?: 'Journey'
  children: Array<JourneyChild>
  description?: Maybe<Scalars['String']>
  heroRemoteUrl: Scalars['String']
  icon?: Maybe<JourneyIcon>
  id: Scalars['ID']
  milestones: Array<Milestone>
  name: Scalars['String']
  permalink: Scalars['String']
  questions: Array<JourneyQuestion>
  resourcePages?: Maybe<Array<ResourcePage>>
  shortDescription?: Maybe<Scalars['String']>
}

export type JourneyAnswer = {
  __typename?: 'JourneyAnswer'
  body: Scalars['String']
  id: Scalars['ID']
}

/** Descendents of a Journey. */
export type JourneyChild = Group | JourneyTile | Milestone

/** The connection type for Journey. */
export type JourneyConnection = {
  __typename?: 'JourneyConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<JourneyEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Journey>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

/** An edge in a connection. */
export type JourneyEdge = {
  __typename?: 'JourneyEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node?: Maybe<Journey>
}

export enum JourneyIcon {
  GetCollege = 'getCollege',
  Job = 'job',
  SuccessCollege = 'successCollege',
}

/** Journey Item */
export type JourneyItem = Group | JourneyTile | Milestone | Submilestone

export type JourneyQuestion = {
  __typename?: 'JourneyQuestion'
  answers: Array<JourneyAnswer>
  body: Scalars['String']
  id: Scalars['ID']
}

export type JourneyTile = {
  __typename?: 'JourneyTile'
  id: Scalars['ID']
  nextMilestones: Array<Milestone>
  nextTiles: Array<JourneyTile>
  permalink: Scalars['String']
  tile: Tile
}

export type Milestone = {
  __typename?: 'Milestone'
  articleCount: Scalars['Int']
  children: Array<MilestoneChild>
  description?: Maybe<Scalars['String']>
  heroRemoteUrl?: Maybe<Scalars['String']>
  id: Scalars['ID']
  /** The milestone's parent journey, or the milestone's parent group's journey. */
  journey: Journey
  name: Scalars['String']
  permalink: Scalars['String']
  resourcePages?: Maybe<Array<ResourcePage>>
}

/** Descendents of a milestone. */
export type MilestoneChild = JourneyTile | Submilestone

/** The connection type for Milestone. */
export type MilestoneConnection = {
  __typename?: 'MilestoneConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MilestoneEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Milestone>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

/** An edge in a connection. */
export type MilestoneEdge = {
  __typename?: 'MilestoneEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node?: Maybe<Milestone>
}

export type Mutation = {
  __typename?: 'Mutation'
  /** Add a comment to a tile */
  createComment: Tile
  /** Join the text line */
  joinTextLine: Scalars['String']
  /** User interacts with content */
  journeyInteraction: Viewer
  /** Update journey question response */
  updateContentfulJourneyResponse: ContentfulQuestionResponse
  /** Update Users profile */
  updateProfile: Student
}

export type MutationCreateCommentArgs = {
  body: Scalars['String']
  tileId: Scalars['ID']
}

export type MutationJoinTextLineArgs = {
  firstName?: InputMaybe<Scalars['String']>
  graduationYear?: InputMaybe<Scalars['Int']>
  lastName?: InputMaybe<Scalars['String']>
  phone: Scalars['String']
}

export type MutationJourneyInteractionArgs = {
  journeyId: Scalars['ID']
}

export type MutationUpdateJourneyResponseArgs = {
  answerId?: InputMaybe<Scalars['ID']>
  questionId: Scalars['ID']
}

export type MutationUpdateProfileArgs = {
  attributes: ProfileAttributes
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo'
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>
}

/** Attributes for updating a profile */
export type ProfileAttributes = {
  address1?: InputMaybe<Scalars['String']>
  address2?: InputMaybe<Scalars['String']>
  city?: InputMaybe<Scalars['String']>
  graduationYear?: InputMaybe<Scalars['Int']>
  firstName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  password?: InputMaybe<Scalars['String']>
  passwordConfirmation?: InputMaybe<Scalars['String']>
  phoneNumber?: InputMaybe<Scalars['String']>
  schoolId?: InputMaybe<Scalars['ID']>
  state?: InputMaybe<State>
  wantsEmailMessages?: InputMaybe<Scalars['Boolean']>
  wantsTextMessages?: InputMaybe<Scalars['Boolean']>
  zipCode?: InputMaybe<Scalars['String']>
}

export type Query = {
  __typename?: 'Query'
  /** Featured tiles */
  featuredTiles?: Maybe<Array<Tile>>
  /** Individual journey */
  journey?: Maybe<Journey>
  /** Individual Journey Tile */
  journeyTile?: Maybe<JourneyTile>
  /** Journeys */
  journeys: JourneyConnection
  /** Individual Milestone */
  milestone?: Maybe<Milestone>
  /** Journey milestones */
  milestones: MilestoneConnection
  /** Published Resource Pages */
  publishedResourcePages: ResourcePageConnection
  /** Published tiles */
  publishedTiles: TileConnection
  /** Individual Resource page */
  resourcePage?: Maybe<ResourcePage>
  schools: SchoolConnection
  searchJobs: JobSearchResult
  /** Search for tiles */
  searchTiles: TileConnection
  /** State Options */
  states: Array<StateOption>
  /** Personalized journey for student */
  contentfulQuestionResponse?: Maybe<ContentfulQuestionResponse>
  /** Completed task of the students */
  courseCompletedTaskResponse?: Maybe<CourseCompletedTaskResponse>
  /** Individual tile */
  tile?: Maybe<Tile>
  /** Individual tile preview */
  tilePreview?: Maybe<Tile>
  /** Current Viewer */
  viewer?: Maybe<Viewer>
}

export type QueryFeaturedTilesArgs = {
  slug: Scalars['String']
}

export type QueryJourneyArgs = {
  id: Scalars['ID']
}

export type QueryJourneyTileArgs = {
  id: Scalars['ID']
}

export type QueryJourneysArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  core?: InputMaybe<Scalars['Boolean']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryMilestoneArgs = {
  id: Scalars['ID']
}

export type QueryMilestonesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryPublishedResourcePagesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryPublishedTilesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryResourcePageArgs = {
  id: Scalars['ID']
}

export type QuerySchoolsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<SchoolFilter>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QuerySearchJobsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  search: JobSearch
}

export type QuerySearchTilesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  query: Scalars['String']
}

export type QueryStudentJourneyArgs = {
  id: Scalars['ID']
}

export type QueryTileArgs = {
  id: Scalars['ID']
}

export type QueryTilePreviewArgs = {
  id: Scalars['ID']
}

export type ResourcePage = {
  __typename?: 'ResourcePage'
  ancestorPages: Array<ResourcePage>
  blurb?: Maybe<Scalars['String']>
  body?: Maybe<Scalars['String']>
  childPages: Array<ResourcePage>
  ctaLabel?: Maybe<Scalars['String']>
  gatedBody?: Maybe<Scalars['String']>
  heroUrl?: Maybe<Scalars['String']>
  id: Scalars['ID']
  isLegal: Scalars['Boolean']
  lede?: Maybe<Scalars['String']>
  permalink: Scalars['String']
  title: Scalars['String']
  updatedAt: Scalars['ISO8601DateTime']
}

/** The connection type for ResourcePage. */
export type ResourcePageConnection = {
  __typename?: 'ResourcePageConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ResourcePageEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ResourcePage>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

/** An edge in a connection. */
export type ResourcePageEdge = {
  __typename?: 'ResourcePageEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node?: Maybe<ResourcePage>
}

export type School = {
  __typename?: 'School'
  city: Scalars['String']
  id: Scalars['ID']
  name: Scalars['String']
  schoolType: Scalars['String']
  state: State
}

/** The connection type for School. */
export type SchoolConnection = {
  __typename?: 'SchoolConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SchoolEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<School>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

/** An edge in a connection. */
export type SchoolEdge = {
  __typename?: 'SchoolEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node?: Maybe<School>
}

/** Filter for searching schools */
export type SchoolFilter = {
  name?: InputMaybe<Filter>
  state?: InputMaybe<Filter>
}

export enum SortEnum {
  /** Sort by Date */
  Date = 'DATE',
  /** sort by relevance (Default) */
  Relevance = 'RELEVANCE',
}

export enum State {
  Aa = 'AA',
  Ae = 'AE',
  Ak = 'AK',
  Al = 'AL',
  Ap = 'AP',
  Ar = 'AR',
  As = 'AS',
  Az = 'AZ',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  Dc = 'DC',
  De = 'DE',
  Fl = 'FL',
  Ga = 'GA',
  Gu = 'GU',
  Hi = 'HI',
  Ia = 'IA',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Ma = 'MA',
  Md = 'MD',
  Me = 'ME',
  Mi = 'MI',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Ms = 'MS',
  Mt = 'MT',
  Nc = 'NC',
  Nd = 'ND',
  Ne = 'NE',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Nv = 'NV',
  Ny = 'NY',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pa = 'PA',
  Pr = 'PR',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Tx = 'TX',
  Um = 'UM',
  Ut = 'UT',
  Va = 'VA',
  Vi = 'VI',
  Vt = 'VT',
  Wa = 'WA',
  Wi = 'WI',
  Wv = 'WV',
  Wy = 'WY',
}

export type StateOption = {
  __typename?: 'StateOption'
  code: State
  name: Scalars['String']
}

export type Student = {
  __typename?: 'Student'
  birthdate: Scalars['String']
  daysSinceLastSignIn: Scalars['Int']
  email: Scalars['String']
  firstName?: Maybe<Scalars['String']>
  gender?: Maybe<Scalars['String']>
  graduationYear?: Maybe<Scalars['Int']>
  id: Scalars['ID']
  lastName?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<Scalars['String']>
  photo?: Maybe<Scalars['String']>
  school?: Maybe<School>
  shortName?: Maybe<Scalars['String']>
  wantsEmailMessages?: Maybe<Scalars['Boolean']>
  wantsTextMessages?: Maybe<Scalars['Boolean']>
}

export type ContentfulQuestionResponse = {
  __typename?: 'ContentfulQuestionResponse'
  contentfulJourneyId: Maybe<Scalars['String']>
  questionId: Maybe<Scalars['String']>
  contentfulAnswerId: Maybe<Scalars['String']>
}

export type CourseCompletedTaskResponse = {
  __typename?: 'CourseCompletedTaskResponse'
  taskId: Maybe<Scalars['String']>
  stepId: Maybe<Scalars['String']>
}

export type StudentJourneyQuestionResponse = {
  __typename?: 'StudentJourneyQuestionResponse'
  answerId: Scalars['ID']
  id: Scalars['ID']
  questionId: Scalars['ID']
}

export type Submilestone = {
  __typename?: 'Submilestone'
  children: Array<JourneyTile>
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  name: Scalars['String']
}

export type Tile = {
  __typename?: 'Tile'
  comments?: Maybe<CommentConnection>
  enableComments?: Maybe<Scalars['Boolean']>
  heroRemoteUrl?: Maybe<Scalars['String']>
  id: Scalars['ID']
  journeySlug: Scalars['String']
  permalink: Scalars['String']
  relatedJourneys: Array<Journey>
  relatedTiles?: Maybe<Array<Tile>>
  summary: Scalars['String']
  tileable: Article
  title: Scalars['String']
  typeName: Scalars['String']
  updatedAt: Scalars['ISO8601DateTime']
}

export type TileCommentsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

/** The connection type for Tile. */
export type TileConnection = {
  __typename?: 'TileConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TileEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Tile>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

/** An edge in a connection. */
export type TileEdge = {
  __typename?: 'TileEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node?: Maybe<Tile>
}

export type UpNext = {
  __typename?: 'UpNext'
  journey: Journey
  milestone?: Maybe<Milestone>
  tiles: Array<JourneyTile>
}

export type Viewer = {
  __typename?: 'Viewer'
  id: Scalars['ID']
  school: School
  student: Student
  upNext?: Maybe<UpNext>
}

export type UpNextPartsFragment = {
  __typename?: 'Viewer'
  id: string
  upNext?: {
    __typename?: 'UpNext'
    journey: {
      __typename?: 'Journey'
      id: string
      permalink: string
      name: string
    }
    milestone?: {
      __typename?: 'Milestone'
      id: string
      permalink: string
      name: string
    } | null
    tiles: Array<{
      __typename?: 'JourneyTile'
      id: string
      permalink: string
      tile: {
        __typename?: 'Tile'
        id: string
        permalink: string
        title: string
        summary: string
        heroRemoteUrl?: string | null
      }
    }>
  } | null
}

export type UpNextJourneyStepsQueryVariables = Exact<{[key: string]: never}>

export type ContentfulInteractionQueryVariables = Exact<{[key: string]: never}>

export type UpNextJourneyStepsQuery = {
  __typename?: 'Query'
  viewer?: {
    __typename?: 'Viewer'
    id: string
    upNext?: {
      __typename?: 'UpNext'
      journey: {
        __typename?: 'Journey'
        id: string
        permalink: string
        name: string
      }
      milestone?: {
        __typename?: 'Milestone'
        id: string
        permalink: string
        name: string
      } | null
      tiles: Array<{
        __typename?: 'JourneyTile'
        id: string
        permalink: string
        tile: {
          __typename?: 'Tile'
          id: string
          permalink: string
          title: string
          summary: string
          heroRemoteUrl?: string | null
        }
      }>
    } | null
  } | null
}

export type ContentfulInteractionQuery = {
  __typename?: 'Query'
  contentfulInteraction?: {
    __typename?: 'ContentfulInteraction'
    contentId: string
    contentType: string
  }
}

export type JourneyInteractionMutationVariables = Exact<{
  contentId: string
  contentType: string
}>

export type TaskStepMutationVariables = Exact<{
  taskId: string
  stepId: string
}>

export type JourneyInteractionMutation = {
  __typename?: 'Mutation'
  journeyContentfulInteraction: {__typename?: 'Viewer'; id: string}
}
export type TaskStepMutation = {
  __typename?: 'Mutation'
  updateContentfulTaskSteps: {__typename?: 'Viewer'; id: string}
}

export type JobSearchQueryVariables = Exact<{
  search: JobSearch
  offset?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
}>

export type JobSearchQuery = {
  __typename?: 'Query'
  searchJobs: {
    __typename?: 'JobSearchResult'
    location: string
    query: string
    radius?: number | null
    pageInfo: {
      __typename?: 'JobSearchPageInfo'
      end: number
      hasMore: boolean
      pageNumber: number
    }
    results: Array<{
      __typename?: 'Job'
      company: string
      formattedLocationFull: string
      id: string
      jobTitle: string
      onmousedown: string
      snippet: string
      url: string
    }>
  }
}

export type StudentPartsFragment = {
  __typename?: 'Student'
  id: string
  firstName?: string | null
  lastName?: string | null
  shortName?: string | null
  name?: string | null
  email: string
  birthdate: string
  phoneNumber?: string | null
  graduationYear?: number | null
  wantsEmailMessages?: boolean | null
  wantsTextMessages?: boolean | null
  school?: {
    __typename?: 'School'
    id: string
    name: string
    state: State
    city: string
  } | null
}

export type ProfileQueryVariables = Exact<{[key: string]: never}>

export type ProfileQuery = {
  __typename?: 'Query'
  viewer?: {
    __typename?: 'Viewer'
    id: string
    student: {
      __typename?: 'Student'
      id: string
      firstName?: string | null
      lastName?: string | null
      shortName?: string | null
      name?: string | null
      email: string
      birthdate: string
      phoneNumber?: string | null
      graduationYear?: number | null
      wantsEmailMessages?: boolean | null
      wantsTextMessages?: boolean | null
      school?: {
        __typename?: 'School'
        id: string
        name: string
        state: State
        city: string
      } | null
    }
  } | null
}

export type UpdateProfileMutationVariables = Exact<{
  attributes: ProfileAttributes
}>

export type UpdateProfileMutation = {
  __typename?: 'Mutation'
  updateProfile: {
    __typename?: 'Student'
    id: string
    firstName?: string | null
    lastName?: string | null
    shortName?: string | null
    name?: string | null
    email: string
    birthdate: string
    phoneNumber?: string | null
    graduationYear?: number | null
    wantsEmailMessages?: boolean | null
    wantsTextMessages?: boolean | null
    school?: {
      __typename?: 'School'
      id: string
      name: string
      state: State
      city: string
    } | null
  }
}

export type CreateSelfreflectionAnswerMutationVariable = Exact<{
  taskId: Scalars['ID']
  answer: Scalars['String']
}>
export type CreateSelfreflectionAnswerMutation = {
  __typename?: 'Mutation'
  createSelfreflectionAnswer: {__typename?: 'Viewer'; id: string}
}

export type CreateCourseCompletedTaskMutationVariable = Exact<{
  taskId: Scalars['ID']
  stepId: Scalars['ID']
}>
export type CreateCourseCompletedTaskMutation = {
  __typename?: 'Mutation'
  createCourseCompletedTask: {__typename?: 'Viewer'; id: string}
}

export type CreateAsaTaskStepMutationVariable = Exact<{
  stepNumber: Scalars['Int']
  userId: Scalars['Int']
  uniqueTaskKey: Scalars['String']
}>
export type CreateAsaTaskStepMutation = {
  __typename?: 'Mutation'
  createAsaTaskStep: {__typename?: 'Viewer'; id: string}
}

export type ResourcePageFragment = {
  __typename?: 'ResourcePage'
  id: string
  title: string
  permalink: string
  body?: string | null
  updatedAt: any
  isLegal: boolean
  gatedBody?: string | null
  ctaLabel?: string | null
  heroUrl?: string | null
  lede?: string | null
  ancestorPages: Array<{
    __typename?: 'ResourcePage'
    id: string
    title: string
    permalink: string
  }>
}

export type ResourcePageQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type ResourcePageQuery = {
  __typename?: 'Query'
  resourcePage?: {
    __typename?: 'ResourcePage'
    id: string
    title: string
    permalink: string
    body?: string | null
    updatedAt: any
    isLegal: boolean
    gatedBody?: string | null
    ctaLabel?: string | null
    heroUrl?: string | null
    lede?: string | null
    ancestorPages: Array<{
      __typename?: 'ResourcePage'
      id: string
      title: string
      permalink: string
    }>
  } | null
}

export type SearchSchoolQueryVariables = Exact<{
  filter: SchoolFilter
  limit?: InputMaybe<Scalars['Int']>
}>

export type SearchSchoolQuery = {
  __typename?: 'Query'
  schools: {
    __typename?: 'SchoolConnection'
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean}
    nodes?: Array<{
      __typename?: 'School'
      id: string
      name: string
      city: string
      state: State
    } | null> | null
  }
}

export type StudentJourneyFragmentFragment = {
  __typename?: 'StudentJourney'
  id: string
  responses: Array<{
    __typename?: 'StudentJourneyQuestionResponse'
    id: string
    questionId: string
    answerId: string
  }>
  journeyItems: Array<
    | {__typename: 'Group'; id: string}
    | {__typename: 'JourneyTile'; id: string}
    | {__typename: 'Milestone'; id: string}
    | {__typename: 'Submilestone'; id: string}
  >
}

export type StudentJourneyQueryVariables = Exact<{
  journeyId: Scalars['String']
}>

export type StudentJourneyQuery = {
  __typename?: 'Query'
  contentfulQuestionResponse?: {
    __typename?: 'ContentfulQuestionResponse'
    contentfulAnswerId: string
    contentfulJourneyId: string
    contentfulQuestionId: string
  }
}

export type CourseCompletedTaskQueryVariables = Exact<{
  taskId: Scalars['String']
}>

export type CourseCompletedTaskQuery = {
  __typename?: 'Query'
  contentfulCompletedTaskSteps?: {
    __typename?: 'ContentfulCompletedTaskSteps'
    taskId: string
    stepId: string
  }[]
}

export type UpdateJourneyResponseMutationVariables = Exact<{
  questionId: Scalars['String']
  journeyId: Scalars['String']
  answerId?: InputMaybe<Scalars['String']>
}>

export type UpdateJourneyResponseMutation = {
  __typename?: 'Mutation'
  updateContentfulJourneyResponse: {
    __typename?: 'ContentfulQuestionResponse'
    contentfulAnswerId: string
    contentfulJourneyId: string
    contentfulQuestionId: string
  }
}

export type JoinTextLineMutationVariables = Exact<{
  phone: Scalars['String']
  firstName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  graduationYear?: InputMaybe<Scalars['Int']>
}>

export type JoinTextLineMutation = {
  __typename?: 'Mutation'
  joinTextLine: string
}

export type TilePartsFragment = {
  __typename?: 'Tile'
  id: string
  permalink: string
  summary: string
  title: string
  typeName: string
  updatedAt: any
  tileable: {__typename?: 'Article'; id: string; body: string}
  relatedTiles?: Array<{
    __typename?: 'Tile'
    id: string
    permalink: string
    title: string
    summary: string
  }> | null
  relatedJourneys: Array<{
    __typename?: 'Journey'
    id: string
    name: string
    permalink: string
  }>
}

export type TileQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type TileQuery = {
  __typename?: 'Query'
  tile?: {
    __typename?: 'Tile'
    id: string
    permalink: string
    summary: string
    title: string
    typeName: string
    updatedAt: any
    tileable: {__typename?: 'Article'; id: string; body: string}
    relatedTiles?: Array<{
      __typename?: 'Tile'
      id: string
      permalink: string
      title: string
      summary: string
    }> | null
    relatedJourneys: Array<{
      __typename?: 'Journey'
      id: string
      name: string
      permalink: string
    }>
  } | null
}

export type TilePreviewQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type TilePreviewQuery = {
  __typename?: 'Query'
  tilePreview?: {
    __typename?: 'Tile'
    id: string
    permalink: string
    summary: string
    title: string
    typeName: string
    updatedAt: any
    tileable: {__typename?: 'Article'; id: string; body: string}
    relatedTiles?: Array<{
      __typename?: 'Tile'
      id: string
      permalink: string
      title: string
      summary: string
    }> | null
    relatedJourneys: Array<{
      __typename?: 'Journey'
      id: string
      name: string
      permalink: string
    }>
  } | null
}

export type CommentPartsFragment = {
  __typename?: 'Comment'
  id: string
  body: string
  createdAt: any
  student: {__typename?: 'Student'; shortName?: string | null}
}

export type CreateCommentMutationVariables = Exact<{
  tileId: Scalars['ID']
  body: Scalars['String']
}>

export type CreateCommentMutation = {
  __typename?: 'Mutation'
  createComment: {
    __typename?: 'Tile'
    id: string
    comments?: {
      __typename?: 'CommentConnection'
      totalCount: number
      pageInfo: {
        __typename?: 'PageInfo'
        hasNextPage: boolean
        endCursor?: string | null
      }
      nodes?: Array<{
        __typename?: 'Comment'
        id: string
        body: string
        createdAt: any
        student: {__typename?: 'Student'; shortName?: string | null}
      } | null> | null
    } | null
  }
}

export type TileCommentsQueryVariables = Exact<{
  id: Scalars['ID']
  after?: InputMaybe<Scalars['String']>
}>

export type ContentfulJourneyTile = {
  __typename?: 'ContentfulJourneyTile'
  id: string
  permalink: string
  tile?: {
    id: string
    permalink: string
    title: string
    summary: {
      summary: string
    }
    heroRemoteUrl?: string | null
  } | null
}

export type ContentfulSubmilestone = {
  __typename?: 'ContentfulSubmilestone'
  id: string
  name: string
  contentfulchildren?: ContentfulJourneyTile[]
}

export type ContentfulMilestone = {
  __typename?: 'ContentfulMilestone'
  id: string
  name: string
  permalink: string
  contentfulchildren?: (ContentfulJourneyTile | ContentfulSubmilestone)[]
}

export type ContentfulJourney = {
  __typename?: 'ContentfulJourney'
  id: string
  permalink: string
  name: string
  contentfulchildren: ContentfulMilestone[]
}

export type InterviewCoachReportCompletedQuery = {
   __typename?: 'Query'
   interviewsCompleted: BigInt
   date: string
}

export type InterviewCoachReportCompletedVariables = Exact<{[key: string]: never}>

export type AllContentfulJourneyQuery = {
  __typename?: 'Query'
  allContentfulJourney?: {
    __typename?: 'ContentfulJourneyConnection'
    nodes?: ContentfulJourney[]
  }
}

export type TileCommentsQuery = {
  __typename?: 'Query'
  tile?: {
    __typename?: 'Tile'
    id: string
    enableComments?: boolean | null
    comments?: {
      __typename?: 'CommentConnection'
      totalCount: number
      pageInfo: {
        __typename?: 'PageInfo'
        hasNextPage: boolean
        endCursor?: string | null
      }
      nodes?: Array<{
        __typename?: 'Comment'
        id: string
        body: string
        createdAt: any
        student: {__typename?: 'Student'; shortName?: string | null}
      } | null> | null
    } | null
  } | null
}

export type CurrentUserFragment = {
  __typename: 'Student'
  id: string
  name?: string | null
  email: string
  shortName?: string | null
  daysSinceLastSignIn: number
}

export type ViewerQueryVariables = Exact<{[key: string]: never}>

export type ViewerQuery = {
  __typename?: 'Query'
  viewer?: {
    __typename: 'Viewer'
    id: string
    student: {
      __typename: 'Student'
      id: string
      name?: string | null
      email: string
      shortName?: string | null
      daysSinceLastSignIn: number
    }
  } | null
}

export const UpNextPartsFragmentDoc = gql`
  fragment UpNextParts on Viewer {
    id
    upNext {
      journey {
        id
        permalink
        name
      }
      milestone {
        id
        permalink
        name
      }
      tiles {
        id
        permalink
        tile {
          id
          permalink
          title
          summary
          heroRemoteUrl
        }
      }
    }
  }
`

export const ContentfulInteractionDoc = gql`
  fragment ContentfulInteraction on ContentfulInteraction {
    contentId
    contentType
  }
`
export const StudentPartsFragmentDoc = gql`
  fragment StudentParts on Student {
    id
    firstName
    lastName
    shortName
    name
    email
    birthdate
    phoneNumber
    graduationYear
    wantsEmailMessages
    wantsTextMessages
    school {
      id
      name
      state
      city
    }
  }
`
export const ResourcePageFragmentDoc = gql`
  fragment ResourcePage on ResourcePage {
    id
    title
    permalink
    body
    updatedAt
    isLegal
    gatedBody
    ctaLabel
    heroUrl
    lede
    ancestorPages {
      id
      title
      permalink
    }
  }
`
export const StudentJourneyFragmentFragmentDoc = gql`
  fragment ContentfulStudentJourneyFragment on ContentfulQuestionResponse {
    contentfulAnswerId
    contentfulJourneyId
    contentfulQuestionId
  }
`
export const TilePartsFragmentDoc = gql`
  fragment TileParts on Tile {
    id
    permalink
    summary
    tileable {
      id
      body
    }
    title
    typeName
    updatedAt
    relatedTiles {
      id
      permalink
      title
      summary
    }
    relatedJourneys {
      id
      name
      permalink
    }
  }
`
export const CommentPartsFragmentDoc = gql`
  fragment CommentParts on Comment {
    id
    body
    createdAt
    student {
      shortName
    }
  }
`
export const CurrentUserFragmentDoc = gql`
  fragment CurrentUser on Student {
    __typename
    id
    name
    email
    shortName
    daysSinceLastSignIn
  }
`
export const UpNextJourneyStepsDocument = gql`
  query UpNextJourneySteps {
    viewer {
      ...UpNextParts
    }
  }
  ${UpNextPartsFragmentDoc}
`

export const ContentfulInteractionDocument = gql`
  query ContentfulInteraction {
    contentfulInteraction {
      ...ContentfulInteraction
    }
  }
  ${ContentfulInteractionDoc}
`
/**
 * __useUpNextJourneyStepsQuery__
 *
 * To run a query within a React component, call `useUpNextJourneyStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpNextJourneyStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpNextJourneyStepsQuery({
 *   variables: {
 *   },
 * });
 */
export function useContentfulInteractionLazyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ContentfulInteractionQuery,
    ContentfulInteractionQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<
    ContentfulInteractionQuery,
    ContentfulInteractionQueryVariables
  >(ContentfulInteractionDocument, options)
}

export function useUpNextJourneyStepsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UpNextJourneyStepsQuery,
    UpNextJourneyStepsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<
    UpNextJourneyStepsQuery,
    UpNextJourneyStepsQueryVariables
  >(UpNextJourneyStepsDocument, options)
}
export function useUpNextJourneyStepsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UpNextJourneyStepsQuery,
    UpNextJourneyStepsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<
    UpNextJourneyStepsQuery,
    UpNextJourneyStepsQueryVariables
  >(UpNextJourneyStepsDocument, options)
}
export type UpNextJourneyStepsQueryHookResult = ReturnType<
  typeof useUpNextJourneyStepsQuery
>
export type UpNextJourneyStepsLazyQueryHookResult = ReturnType<
  typeof useUpNextJourneyStepsLazyQuery
>
export type UpNextJourneyStepsQueryResult = Apollo.QueryResult<
  UpNextJourneyStepsQuery,
  UpNextJourneyStepsQueryVariables
>
export const JourneyInteractionDocument = gql`
  mutation JourneyContentfulInteraction(
    $contentId: String!
    $contentType: String!
  ) {
    journeyContentfulInteraction(
      contentId: $contentId
      contentType: $contentType
    ) {
      id
    }
  }
`

export const TaskStepDocument = gql`
  mutation UpdateContentfulTaskSteps($taskId: String!, $stepId: String!) {
    updateContentfulTaskSteps(taskId: $taskId, taskId: $taskId) {
      id
    }
  }
`
export type JourneyInteractionMutationFn = Apollo.MutationFunction<
  JourneyInteractionMutation,
  JourneyInteractionMutationVariables
>

/**
 * __useJourneyInteractionMutation__
 *
 * To run a mutation, you first call `useJourneyInteractionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJourneyInteractionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [journeyInteractionMutation, { data, loading, error }] = useJourneyInteractionMutation({
 *   variables: {
 *      journeyId: // value for 'journeyId'
 *   },
 * });
 */
export function useJourneyInteractionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JourneyInteractionMutation,
    JourneyInteractionMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<
    JourneyInteractionMutation,
    JourneyInteractionMutationVariables
  >(JourneyInteractionDocument, options)
}

export function useCompleteTaskStepMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TaskStepMutation,
    TaskStepMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<TaskStepMutation, TaskStepMutationVariables>(
    TaskStepDocument,
    options
  )
}

export type JourneyInteractionMutationHookResult = ReturnType<
  typeof useJourneyInteractionMutation
>
export type JourneyInteractionMutationResult =
  Apollo.MutationResult<JourneyInteractionMutation>
export type JourneyInteractionMutationOptions = Apollo.BaseMutationOptions<
  JourneyInteractionMutation,
  JourneyInteractionMutationVariables
>
export const useCreateInterviewAndTimestampMutation = (baseOptions?: Apollo.MutationHookOptions<CreateInterviewAndTimestampMutation, CreateInterviewAndTimestampMutationVariables>) => {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(CreateInterviewAndTimestampDocument, options);
};
      
export const CreateInterviewAndTimestampDocument = gql`
  mutation UpdateInterviewMutation($eventType: String!) {
    updateInterviewMutation(eventType: $eventType) {
      id
    }
  }
`;

export type CreateInterviewAndTimestampMutation = {
  __typename?: 'Mutation';
  updateInterviewMutation: {
    __typename?: 'Viewer';
    id: string;
  };
};

export type CreateInterviewAndTimestampMutationVariables = Exact<{
  eventType: string;
}>;

export const useCreateCourseDatapointMutation = (baseOptions?: Apollo.MutationHookOptions<CreateCourseDatapointMutation, CreateCourseDatapointMutationVariables>) => {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation(CreateCourseDatapointDocument, options);
};
      
export const CreateCourseDatapointDocument = gql`
  mutation CreateCourseDatapoint($contentfulId: String!, $courseTitle: String!, $status: String!, $source: String!) {
    createCourseDatapoint(contentfulId: $contentfulId, courseTitle: $courseTitle, status: $status, source: $source) {
      id
    }
  }
`;

export type CreateCourseDatapointMutation = {
  __typename?: 'Mutation';
  createCourseDatapoint: {
    __typename?: 'Viewer';
    id: string;
  };
};

export type CreateCourseDatapointMutationVariables = Exact<{
  contentfulId: string;
  courseTitle: string;
  status: string;
  source: string;
}>;

export const JobSearchDocument = gql`
  query JobSearch($search: JobSearch!, $offset: Int, $limit: Int) {
    searchJobs(search: $search, offset: $offset, limit: $limit) {
      pageInfo {
        end
        hasMore
        pageNumber
      }
      location
      query
      radius
      results {
        company
        formattedLocationFull
        id
        jobTitle
        onmousedown
        snippet
        url
      }
    }
  }
`

/**
 * __useJobSearchQuery__
 *
 * To run a query within a React component, call `useJobSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobSearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useJobSearchQuery(
  baseOptions: Apollo.QueryHookOptions<JobSearchQuery, JobSearchQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<JobSearchQuery, JobSearchQueryVariables>(
    JobSearchDocument,
    options
  )
}
export function useJobSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobSearchQuery,
    JobSearchQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<JobSearchQuery, JobSearchQueryVariables>(
    JobSearchDocument,
    options
  )
}
export type JobSearchQueryHookResult = ReturnType<typeof useJobSearchQuery>
export type JobSearchLazyQueryHookResult = ReturnType<
  typeof useJobSearchLazyQuery
>
export type JobSearchQueryResult = Apollo.QueryResult<
  JobSearchQuery,
  JobSearchQueryVariables
>
export const ProfileDocument = gql`
  query Profile {
    viewer {
      id
      student {
        ...StudentParts
      }
    }
  }
  ${StudentPartsFragmentDoc}
`

/**
 * __useProfileQuery__
 *
 * To run a query within a React component, call `useProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<ProfileQuery, ProfileQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<ProfileQuery, ProfileQueryVariables>(
    ProfileDocument,
    options
  )
}
export function useProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProfileQuery, ProfileQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<ProfileQuery, ProfileQueryVariables>(
    ProfileDocument,
    options
  )
}
export type ProfileQueryHookResult = ReturnType<typeof useProfileQuery>
export type ProfileLazyQueryHookResult = ReturnType<typeof useProfileLazyQuery>
export type ProfileQueryResult = Apollo.QueryResult<
  ProfileQuery,
  ProfileQueryVariables
>
export const UpdateProfileDocument = gql`
  mutation UpdateProfile($attributes: ProfileAttributes!) {
    updateProfile(attributes: $attributes) {
      ...StudentParts
    }
  }
  ${StudentPartsFragmentDoc}
`
export type UpdateProfileMutationFn = Apollo.MutationFunction<
  UpdateProfileMutation,
  UpdateProfileMutationVariables
>

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProfileMutation,
    UpdateProfileMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<
    UpdateProfileMutation,
    UpdateProfileMutationVariables
  >(UpdateProfileDocument, options)
}
export type UpdateProfileMutationHookResult = ReturnType<
  typeof useUpdateProfileMutation
>
export type UpdateProfileMutationResult =
  Apollo.MutationResult<UpdateProfileMutation>
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateProfileMutation,
  UpdateProfileMutationVariables
>
export const ResourcePageDocument = gql`
  query ResourcePage($id: ID!) {
    resourcePage(id: $id) {
      ...ResourcePage
    }
  }
  ${ResourcePageFragmentDoc}
`

export const CreateSelfreflectionAnswerDocument = gql`
  mutation CreateSelfreflectionAnswer($taskId: String!, $answer: String!) {
    createSelfreflectionAnswer(taskId: $taskId, answer: $answer) {
      id
    }
  }
`

export function useCreateSelfreflectionAnswerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSelfreflectionAnswerMutation,
    CreateSelfreflectionAnswerMutationVariable
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<
    CreateSelfreflectionAnswerMutation,
    CreateSelfreflectionAnswerMutationVariable
  >(CreateSelfreflectionAnswerDocument, options)
}

export const CreateCourseCompletedTaskDocument = gql`
  mutation CreateCourseCompletedTask($taskId: String!, $stepId: String!) {
    createCourseCompletedTask(taskId: $taskId, stepId: $stepId) {
      id
    }
  }
`

export const CreateAsaTaskStepDocument = gql`
  mutation CreateAsaTaskStep($stepNumber: Int!, $userId: Int!, $uniqueTaskKey: String!) {
    createAsaTaskStep(stepNumber: $stepNumber, userId: $userId, uniqueTaskKey: $uniqueTaskKey) {
      id
    }
  }
`

export function useCreateCourseCompletedTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCourseCompletedTaskMutation,
    CreateCourseCompletedTaskMutationVariable
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<
    CreateCourseCompletedTaskMutation,
    CreateCourseCompletedTaskMutationVariable
  >(CreateCourseCompletedTaskDocument, options)
}

export function useCreateAsaTaskStepMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAsaTaskStepMutation,
    CreateAsaTaskStepMutationVariable
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<
  CreateAsaTaskStepMutation,
  CreateAsaTaskStepMutationVariable
  >(CreateAsaTaskStepDocument, options)
}

/**
 * __useResourcePageQuery__
 *
 * To run a query within a React component, call `useResourcePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourcePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourcePageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResourcePageQuery(
  baseOptions: Apollo.QueryHookOptions<
    ResourcePageQuery,
    ResourcePageQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<ResourcePageQuery, ResourcePageQueryVariables>(
    ResourcePageDocument,
    options
  )
}
export function useResourcePageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResourcePageQuery,
    ResourcePageQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<ResourcePageQuery, ResourcePageQueryVariables>(
    ResourcePageDocument,
    options
  )
}
export type ResourcePageQueryHookResult = ReturnType<
  typeof useResourcePageQuery
>
export type ResourcePageLazyQueryHookResult = ReturnType<
  typeof useResourcePageLazyQuery
>
export type ResourcePageQueryResult = Apollo.QueryResult<
  ResourcePageQuery,
  ResourcePageQueryVariables
>
export const SearchSchoolDocument = gql`
  query SearchSchool($filter: SchoolFilter!, $limit: Int) {
    schools(filter: $filter, first: $limit) {
      pageInfo {
        hasNextPage
      }
      nodes {
        id
        name
        city
        state
      }
    }
  }
`

/**
 * __useSearchSchoolQuery__
 *
 * To run a query within a React component, call `useSearchSchoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchSchoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchSchoolQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchSchoolQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchSchoolQuery,
    SearchSchoolQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<SearchSchoolQuery, SearchSchoolQueryVariables>(
    SearchSchoolDocument,
    options
  )
}
export function useSearchSchoolLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchSchoolQuery,
    SearchSchoolQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<SearchSchoolQuery, SearchSchoolQueryVariables>(
    SearchSchoolDocument,
    options
  )
}
export type SearchSchoolQueryHookResult = ReturnType<
  typeof useSearchSchoolQuery
>
export type SearchSchoolLazyQueryHookResult = ReturnType<
  typeof useSearchSchoolLazyQuery
>
export type SearchSchoolQueryResult = Apollo.QueryResult<
  SearchSchoolQuery,
  SearchSchoolQueryVariables
>
export const StudentJourneyDocument = gql`
  query ContentfulQuestionResponse($journeyId: String!) {
    contentfulQuestionResponse(journeyId: $journeyId) {
      ...ContentfulStudentJourneyFragment
    }
  }
  ${StudentJourneyFragmentFragmentDoc}
`

export const CourseCompletedTaskDocument = gql`
  query ContentfulCompletedTaskSteps($taskId: String!) {
    contentfulCompletedTaskSteps(taskId: $taskId) {
      stepId
      taskId
    }
  }
`
/**
 * __useStudentJourneyQuery__
 *
 * To run a query within a React component, call `useStudentJourneyQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentJourneyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentJourneyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStudentJourneyQuery(
  baseOptions: Apollo.QueryHookOptions<
    StudentJourneyQuery,
    StudentJourneyQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<StudentJourneyQuery, StudentJourneyQueryVariables>(
    StudentJourneyDocument,
    options
  )
}
export function useCourseCompletedTaskQuery(
  baseOptions: Apollo.QueryHookOptions<
    CourseCompletedTaskQuery,
    CourseCompletedTaskQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<
    CourseCompletedTaskQuery,
    CourseCompletedTaskQueryVariables
  >(CourseCompletedTaskDocument, options)
}
export function useStudentJourneyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StudentJourneyQuery,
    StudentJourneyQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<StudentJourneyQuery, StudentJourneyQueryVariables>(
    StudentJourneyDocument,
    options
  )
}
export type StudentJourneyQueryHookResult = ReturnType<
  typeof useStudentJourneyQuery
>
export type StudentJourneyLazyQueryHookResult = ReturnType<
  typeof useStudentJourneyLazyQuery
>
export type StudentJourneyQueryResult = Apollo.QueryResult<
  StudentJourneyQuery,
  StudentJourneyQueryVariables
>
export const UpdateJourneyResponseDocument = gql`
  mutation UpdateContentfulJourneyResponse(
    $questionId: String!
    $answerId: String
    $journeyId: String!
  ) {
    updateContentfulJourneyResponse(
      contentfulQuestionId: $questionId
      contentfulAnswerId: $answerId
      contentfulJourneyId: $journeyId
    ) {
      ...ContentfulStudentJourneyFragment
    }
  }
  ${StudentJourneyFragmentFragmentDoc}
`
export type UpdateJourneyResponseMutationFn = Apollo.MutationFunction<
  UpdateJourneyResponseMutation,
  UpdateJourneyResponseMutationVariables
>

/**
 * __useUpdateJourneyResponseMutation__
 *
 * To run a mutation, you first call `useUpdateJourneyResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJourneyResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJourneyResponseMutation, { data, loading, error }] = useUpdateJourneyResponseMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *      answerId: // value for 'answerId'
 *   },
 * });
 */
export function useUpdateJourneyResponseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateJourneyResponseMutation,
    UpdateJourneyResponseMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<
    UpdateJourneyResponseMutation,
    UpdateJourneyResponseMutationVariables
  >(UpdateJourneyResponseDocument, options)
}
export type UpdateJourneyResponseMutationHookResult = ReturnType<
  typeof useUpdateJourneyResponseMutation
>
export type UpdateJourneyResponseMutationResult =
  Apollo.MutationResult<UpdateJourneyResponseMutation>
export type UpdateJourneyResponseMutationOptions = Apollo.BaseMutationOptions<
  UpdateJourneyResponseMutation,
  UpdateJourneyResponseMutationVariables
>
export const JoinTextLineDocument = gql`
  mutation JoinTextLine(
    $phone: String!
    $firstName: String
    $lastName: String
    $graduationYear: Int
  ) {
    joinTextLine(
      phone: $phone
      firstName: $firstName
      lastName: $lastName
      graduationYear: $graduationYear
    )
  }
`
export type JoinTextLineMutationFn = Apollo.MutationFunction<
  JoinTextLineMutation,
  JoinTextLineMutationVariables
>

/**
 * __useJoinTextLineMutation__
 *
 * To run a mutation, you first call `useJoinTextLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinTextLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinTextLineMutation, { data, loading, error }] = useJoinTextLineMutation({
 *   variables: {
 *      phone: // value for 'phone'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      graduationYear: // value for 'graduationYear'
 *   },
 * });
 */
export function useJoinTextLineMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JoinTextLineMutation,
    JoinTextLineMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<
    JoinTextLineMutation,
    JoinTextLineMutationVariables
  >(JoinTextLineDocument, options)
}
export type JoinTextLineMutationHookResult = ReturnType<
  typeof useJoinTextLineMutation
>
export type JoinTextLineMutationResult =
  Apollo.MutationResult<JoinTextLineMutation>
export type JoinTextLineMutationOptions = Apollo.BaseMutationOptions<
  JoinTextLineMutation,
  JoinTextLineMutationVariables
>
export const TileDocument = gql`
  query Tile($id: ID!) {
    tile(id: $id) {
      ...TileParts
    }
  }
  ${TilePartsFragmentDoc}
`

/**
 * __useTileQuery__
 *
 * To run a query within a React component, call `useTileQuery` and pass it any options that fit your needs.
 * When your component renders, `useTileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTileQuery(
  baseOptions: Apollo.QueryHookOptions<TileQuery, TileQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<TileQuery, TileQueryVariables>(TileDocument, options)
}
export function useTileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TileQuery, TileQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<TileQuery, TileQueryVariables>(
    TileDocument,
    options
  )
}
export type TileQueryHookResult = ReturnType<typeof useTileQuery>
export type TileLazyQueryHookResult = ReturnType<typeof useTileLazyQuery>
export type TileQueryResult = Apollo.QueryResult<TileQuery, TileQueryVariables>
export const TilePreviewDocument = gql`
  query TilePreview($id: ID!) {
    tilePreview(id: $id) {
      ...TileParts
    }
  }
  ${TilePartsFragmentDoc}
`

/**
 * __useTilePreviewQuery__
 *
 * To run a query within a React component, call `useTilePreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useTilePreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTilePreviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTilePreviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    TilePreviewQuery,
    TilePreviewQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<TilePreviewQuery, TilePreviewQueryVariables>(
    TilePreviewDocument,
    options
  )
}
export function useTilePreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TilePreviewQuery,
    TilePreviewQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<TilePreviewQuery, TilePreviewQueryVariables>(
    TilePreviewDocument,
    options
  )
}
export type TilePreviewQueryHookResult = ReturnType<typeof useTilePreviewQuery>
export type TilePreviewLazyQueryHookResult = ReturnType<
  typeof useTilePreviewLazyQuery
>
export type TilePreviewQueryResult = Apollo.QueryResult<
  TilePreviewQuery,
  TilePreviewQueryVariables
>
export const CreateCommentDocument = gql`
  mutation CreateComment($tileId: ID!, $body: String!) {
    createComment(tileId: $tileId, body: $body) {
      id
      comments(first: 10) {
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          ...CommentParts
        }
      }
    }
  }
  ${CommentPartsFragmentDoc}
`
export type CreateCommentMutationFn = Apollo.MutationFunction<
  CreateCommentMutation,
  CreateCommentMutationVariables
>

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      tileId: // value for 'tileId'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCommentMutation,
    CreateCommentMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<
    CreateCommentMutation,
    CreateCommentMutationVariables
  >(CreateCommentDocument, options)
}
export type CreateCommentMutationHookResult = ReturnType<
  typeof useCreateCommentMutation
>
export type CreateCommentMutationResult =
  Apollo.MutationResult<CreateCommentMutation>
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<
  CreateCommentMutation,
  CreateCommentMutationVariables
>
export const TileCommentsDocument = gql`
  query TileComments($id: ID!, $after: String) {
    tile(id: $id) {
      id
      enableComments
      comments(first: 10, after: $after) {
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          ...CommentParts
        }
      }
    }
  }
  ${CommentPartsFragmentDoc}
`

/**
 * __useTileCommentsQuery__
 *
 * To run a query within a React component, call `useTileCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTileCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTileCommentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useTileCommentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TileCommentsQuery,
    TileCommentsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<TileCommentsQuery, TileCommentsQueryVariables>(
    TileCommentsDocument,
    options
  )
}

export const InterviewCompleted = gql`
  query InterviewCompletedCount {
    interviewCoachReportCompleted {
      interviewsCompleted
      date
    }
  }`

export function useInterviewCoachReportCompletedQuery(
  baseOptions?: Apollo.QueryHookOptions<
  InterviewCoachReportCompletedQuery,
  InterviewCoachReportCompletedVariables
>
) {
  const options = {...defaultOptions, ...baseOptions }
  return Apollo.useQuery<any, any>(
    InterviewCompleted,
    options
  )
}

export function useTileCommentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TileCommentsQuery,
    TileCommentsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<TileCommentsQuery, TileCommentsQueryVariables>(
    TileCommentsDocument,
    options
  )
}
export type TileCommentsQueryHookResult = ReturnType<
  typeof useTileCommentsQuery
>
export type TileCommentsLazyQueryHookResult = ReturnType<
  typeof useTileCommentsLazyQuery
>
export type TileCommentsQueryResult = Apollo.QueryResult<
  TileCommentsQuery,
  TileCommentsQueryVariables
>
export const ViewerDocument = gql`
  query Viewer {
    viewer {
      __typename
      id
      student {
        ...CurrentUser
      }
    }
  }
  ${CurrentUserFragmentDoc}
`

/**
 * __useViewerQuery__
 *
 * To run a query within a React component, call `useViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerQuery(
  baseOptions?: Apollo.QueryHookOptions<ViewerQuery, ViewerQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<ViewerQuery, ViewerQueryVariables>(
    ViewerDocument,
    options
  )
}
export function useViewerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ViewerQuery, ViewerQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<ViewerQuery, ViewerQueryVariables>(
    ViewerDocument,
    options
  )
}
export type ViewerQueryHookResult = ReturnType<typeof useViewerQuery>
export type ViewerLazyQueryHookResult = ReturnType<typeof useViewerLazyQuery>
export type ViewerQueryResult = Apollo.QueryResult<
  ViewerQuery,
  ViewerQueryVariables
>
